export function trouveColor(color) {

    let result;

    switch (color) {
        case 'red':
            result=0xc42020
          break
        case 'green':
            result=0x27d80e
          break
        case 'blue':
            result=0x1b28c1
          break
        case 'violet':
            result=0xd92ee1
          break
        case 'yellow':
            result=0xfafa1e
          break
        default:
            result=0xc42020
          break
      }

      return result;
}

export async function PreChargeImg(path) {

  const Pre = new Promise((resolve, reject) => {
    try {
      console.log('load', path);
      var imageObject = new Image();
      imageObject.src = path;
      imageObject.onload = () => resolve('OK'  + path)
    } catch (error) {
      reject(() => console.log('Err', error))
    }
  }).then(() => {
    console.log('OK -> ' + path);
  })

}