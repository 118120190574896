import { createStore } from 'vuex'

export default createStore({
  state: {
    nbSlide: 1,
    directory: '',
    titreProject: ''
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
