<template>
  <div class="home" id="home">
    <div class="entete" id="entete">
      <!--ATOM-DEV / FULL-STACK-->

      <svg
        version="1.0"
        class="titre-svg"
        xmlns="http://www.w3.org/2000/svg"
        width="500.000000pt"
        height="150.000000pt"
        viewBox="0 0 600.000000 280.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
          fill="none"
          stroke="#ffffff"
          stroke-width="8"
        >
          <path
            d="M1314 1102 c-115 -56 -180 -167 -172 -297 11 -162 114 -272 269 -283
170 -12 306 109 317 283 11 175 -112 315 -283 323 -61 3 -78 -1 -131 -26z
m224 -51 c115 -59 165 -227 103 -349 -30 -59 -94 -109 -156 -123 -174 -39
-320 125 -275 310 37 154 193 231 328 162z"
          />
          <path
            d="M1361 1029 c-105 -42 -160 -179 -115 -287 42 -102 163 -158 261 -121
40 15 92 67 114 114 48 103 2 242 -95 286 -44 20 -125 24 -165 8z m155 -64
c32 -21 63 -88 64 -135 0 -45 -37 -118 -73 -144 -18 -14 -44 -21 -72 -21 -28
0 -54 7 -72 21 -35 26 -73 98 -73 141 0 71 46 140 105 156 33 9 94 0 121 -18z"
          />
          <path
            d="M327 1109 c-92 -22 -200 -106 -243 -191 -81 -160 -67 -380 30 -494
37 -43 113 -85 124 -68 4 6 22 32 41 58 27 39 31 50 20 57 -8 5 -17 9 -20 9
-20 0 -81 80 -94 124 -27 90 -32 86 110 86 l125 0 0 -80 0 -80 70 0 70 0 0
295 0 295 -97 -1 c-54 -1 -115 -5 -136 -10z m173 -284 c0 -157 -4 -245 -10
-245 -6 0 -10 33 -10 80 l0 80 -182 -2 -183 -3 1 -70 c1 -81 26 -141 82 -192
32 -30 36 -53 8 -53 -21 0 -53 41 -83 105 -25 54 -27 71 -27 165 0 84 5 116
23 161 24 63 83 136 135 168 44 27 129 50 194 50 l52 1 0 -245z"
          />
          <path
            d="M320 1015 c-64 -20 -123 -75 -159 -147 -52 -103 -58 -98 144 -98
l175 0 0 130 0 130 -57 -1 c-32 0 -78 -7 -103 -14z m100 -115 l0 -80 -109 0
c-102 0 -109 1 -104 19 13 51 90 115 158 132 56 13 55 14 55 -71z"
          />
          <path
            d="M604 1106 c-3 -7 -4 -38 -2 -67 l3 -54 88 -3 87 -3 0 -224 0 -225 70
0 70 0 0 225 0 224 83 3 82 3 7 55 c4 30 5 61 3 68 -7 19 -484 17 -491 -2z
m426 -56 c0 -18 -7 -20 -85 -20 l-85 0 0 -225 c0 -143 -4 -225 -10 -225 -6 0
-10 82 -10 225 l0 225 -90 0 c-83 0 -90 1 -90 20 0 19 7 20 185 20 178 0 185
-1 185 -20z"
          />
          <path
            d="M1800 825 l0 -295 75 0 75 0 2 169 3 170 73 -105 c100 -142 105 -142
207 3 l75 107 0 -172 0 -172 70 0 70 0 0 295 0 295 -69 0 -69 0 -88 -125 c-49
-68 -92 -124 -95 -125 -3 0 -45 56 -93 125 l-88 125 -74 0 -74 0 0 -295z m215
103 c55 -78 105 -143 111 -145 7 -2 58 62 115 142 81 114 108 145 126 145 l23
0 0 -245 c0 -157 -4 -245 -10 -245 -6 0 -10 80 -10 220 0 170 -3 220 -13 220
-6 0 -59 -67 -117 -150 -58 -82 -107 -149 -110 -149 -3 0 -52 67 -109 149 -58
83 -111 150 -118 150 -10 0 -13 -45 -13 -220 0 -189 -2 -220 -15 -220 -13 0
-15 34 -15 245 l0 245 28 0 c23 0 40 -19 127 -142z"
          />
          <path
            d="M3030 826 l0 -296 154 0 c164 0 207 8 278 55 20 14 50 46 65 72 25
44 28 57 28 143 0 111 -23 174 -83 233 -69 67 -117 81 -289 85 l-153 4 0 -296z
m269 233 c112 -24 185 -107 197 -225 9 -83 -9 -140 -60 -190 -50 -50 -102 -64
-246 -64 l-100 0 0 245 0 245 80 0 c43 0 101 -5 129 -11z"
          />
          <path
            d="M3120 826 l0 -206 108 0 c116 0 167 15 209 61 63 69 56 197 -15 272
-50 53 -98 70 -204 75 l-98 4 0 -206z m177 139 c87 -26 135 -103 119 -190 -14
-73 -69 -104 -183 -105 l-53 0 0 155 0 155 33 0 c18 0 56 -7 84 -15z"
          />
          <path
            d="M3630 825 l0 -295 214 0 c118 0 217 3 219 8 3 4 8 35 12 70 l7 62
-156 0 -156 0 0 60 0 60 109 0 108 0 7 38 c3 20 6 52 6 70 l0 32 -115 0 -115
0 0 25 0 24 148 3 147 3 6 60 c4 33 5 63 2 68 -2 4 -103 7 -224 7 l-219 0 0
-295z m378 228 c-3 -16 -20 -18 -151 -21 l-147 -3 0 -74 0 -75 115 0 c63 0
115 -2 115 -4 0 -32 -14 -36 -121 -36 l-109 0 0 -110 0 -110 150 0 c143 0 150
-1 150 -20 0 -19 -7 -20 -160 -20 l-160 0 0 245 0 245 161 0 c147 0 160 -1
157 -17z"
          />
          <path
            d="M4110 1108 c1 -7 47 -139 103 -293 l102 -280 86 -3 86 -3 101 285
c56 156 102 289 102 295 0 7 -26 11 -69 11 l-70 0 -19 -52 c-10 -29 -43 -122
-72 -206 -29 -84 -56 -149 -61 -145 -4 4 -38 97 -75 206 l-68 197 -73 0 c-52
0 -73 -4 -73 -12z m180 -263 l76 -220 36 -3 35 -3 52 148 c29 81 64 183 79
225 15 43 32 78 39 78 12 0 -1 -38 -114 -350 l-49 -135 -43 0 -43 0 -58 160
c-32 88 -72 197 -89 243 -25 69 -27 83 -14 80 11 -2 39 -70 93 -223z"
          />
          <path
            d="M2756 818 c-3 -7 -11 -35 -20 -62 -9 -27 -16 -53 -16 -58 0 -5 53 -7
117 -6 l118 3 16 60 c9 33 14 63 12 68 -8 13 -223 8 -227 -5z m157 -58 c-5
-17 -14 -20 -63 -20 -53 0 -58 2 -53 20 5 17 14 20 63 20 53 0 58 -2 53 -20z"
          />
          <path
            d="M4167 476 c-67 -25 -99 -65 -112 -142 -15 -91 40 -181 124 -204 71
-19 148 8 181 63 30 48 26 64 -15 69 -19 2 -35 0 -35 -4 0 -22 -55 -58 -87
-58 -81 0 -125 112 -68 174 36 39 94 37 129 -5 14 -17 26 -35 26 -40 0 -5 12
-4 28 2 15 5 33 12 40 14 20 7 -33 102 -67 119 -44 23 -101 28 -144 12z m160
-51 c18 -21 33 -47 33 -57 0 -27 -35 -22 -48 6 -7 16 -25 29 -51 37 -117 35
-195 -95 -115 -190 33 -39 80 -49 128 -26 19 9 38 25 41 36 7 22 45 26 45 4 0
-8 -15 -32 -34 -53 -33 -35 -37 -37 -100 -37 -58 0 -68 3 -96 30 -44 42 -60
76 -60 130 0 53 16 89 57 126 38 36 49 39 112 35 49 -3 58 -7 88 -41z"
          />
          <path
            d="M670 300 l0 -180 78 0 c99 0 148 14 187 54 39 38 50 89 35 162 -21
101 -91 144 -234 144 l-66 0 0 -180z m216 134 c49 -36 67 -70 72 -132 5 -70
-13 -113 -61 -142 -25 -16 -50 -20 -119 -20 l-88 0 0 161 0 161 84 -3 c66 -3
91 -9 112 -25z"
          />
          <path
            d="M720 300 l0 -123 71 5 c84 6 120 28 133 80 7 28 5 44 -10 74 -27 53
-59 73 -131 80 l-63 7 0 -123z m130 85 c40 -21 60 -53 60 -96 0 -60 -26 -82
-104 -87 l-66 -4 0 101 0 101 40 0 c23 0 54 -7 70 -15z"
          />
          <path
            d="M1020 300 l0 -180 130 0 c117 0 130 2 130 18 0 9 3 27 6 40 6 22 5
22 -90 22 l-96 0 0 40 0 40 64 0 c58 0 64 2 70 23 14 54 11 57 -64 57 -63 0
-70 2 -70 20 0 19 7 20 89 20 l89 0 4 40 4 40 -133 0 -133 0 0 -180z m250 140
c0 -19 -7 -20 -95 -20 l-95 0 0 -40 0 -40 70 0 c63 0 70 -2 70 -20 0 -18 -7
-20 -70 -20 l-70 0 0 -60 0 -60 95 0 c88 0 95 -1 95 -20 0 -19 -7 -20 -115
-20 l-115 0 0 160 0 160 115 0 c108 0 115 -1 115 -20z"
          />
          <path
            d="M1312 468 c2 -7 31 -88 64 -181 l59 -167 45 0 45 0 59 168 c32 92 61
173 64 180 3 10 -5 13 -33 10 l-38 -3 -45 -132 c-25 -73 -48 -133 -52 -133 -3
0 -26 60 -51 133 l-46 132 -38 3 c-28 3 -36 0 -33 -10z m108 -148 c30 -86 52
-135 61 -135 8 0 23 27 37 65 63 181 75 210 88 210 8 0 14 -3 14 -7 0 -5 -24
-75 -53 -158 l-52 -150 -35 -3 -35 -3 -53 153 c-29 84 -52 157 -52 161 0 5 7
7 16 5 11 -2 32 -48 64 -138z"
          />
          <path
            d="M1810 300 l0 -180 40 0 40 0 0 70 0 70 64 0 c67 0 76 6 76 51 0 17
-8 19 -70 19 l-70 0 0 35 0 35 89 0 89 0 4 40 4 40 -133 0 -133 0 0 -180z
m250 140 c0 -19 -7 -20 -95 -20 l-95 0 0 -55 0 -55 70 0 c56 0 70 -3 70 -15 0
-12 -14 -15 -70 -15 l-70 0 0 -70 c0 -63 -2 -70 -20 -70 -19 0 -20 7 -20 160
l0 160 115 0 c108 0 115 -1 115 -20z"
          />
          <path
            d="M2110 301 l0 -181 35 0 35 0 0 70 c0 70 0 70 28 70 23 0 34 -10 65
-56 20 -31 37 -63 37 -70 0 -10 14 -14 45 -14 25 0 45 2 45 5 0 11 -43 90 -61
114 -24 30 -24 41 -1 41 48 1 81 67 62 125 -18 53 -55 68 -182 73 l-108 4 0
-181z m249 133 c25 -20 31 -34 31 -63 0 -44 -21 -67 -74 -82 l-37 -10 26 -31
c14 -17 35 -48 46 -70 20 -39 20 -40 1 -36 -12 2 -39 31 -64 68 -41 61 -45 65
-85 68 l-43 3 0 -70 c0 -56 -3 -71 -15 -71 -13 0 -15 24 -15 160 l0 160 99 0
c92 0 102 -2 130 -26z"
          />
          <path
            d="M2160 365 l0 -58 84 5 c90 5 116 16 116 53 0 37 -26 48 -116 53 l-84
5 0 -58z m173 13 c20 -32 -8 -48 -84 -48 l-69 0 0 35 0 36 71 -3 c54 -2 75 -7
82 -20z"
          />
          <path
            d="M2450 300 l0 -180 130 0 c117 0 130 2 130 18 0 9 3 27 6 40 6 22 5
22 -90 22 l-96 0 0 40 0 40 64 0 c58 0 64 2 70 23 14 54 11 57 -64 57 -63 0
-70 2 -70 20 0 19 7 20 89 20 l89 0 4 40 4 40 -133 0 -133 0 0 -180z m250 140
c0 -19 -7 -20 -95 -20 l-95 0 0 -40 0 -40 70 0 c63 0 70 -2 70 -20 0 -18 -7
-20 -70 -20 l-70 0 0 -60 0 -60 95 0 c88 0 95 -1 95 -20 0 -19 -7 -20 -115
-20 l-115 0 0 160 0 160 115 0 c108 0 115 -1 115 -20z"
          />
          <path
            d="M2750 300 l0 -180 130 0 c117 0 130 2 130 18 0 9 3 27 6 40 6 22 5
22 -90 22 l-96 0 0 40 0 40 64 0 c58 0 64 2 70 23 14 54 11 57 -64 57 -63 0
-70 2 -70 20 0 19 7 20 89 20 l89 0 4 40 4 40 -133 0 -133 0 0 -180z m250 140
c0 -19 -7 -20 -95 -20 l-95 0 0 -40 0 -40 70 0 c63 0 70 -2 70 -20 0 -18 -7
-20 -70 -20 l-70 0 0 -60 0 -60 95 0 c88 0 95 -1 95 -20 0 -19 -7 -20 -115
-20 l-115 0 0 160 0 160 115 0 c108 0 115 -1 115 -20z"
          />
          <path
            d="M3050 300 l0 -180 119 0 119 0 6 31 c3 17 6 35 6 40 0 5 -40 9 -90 9
l-90 0 0 140 0 140 -35 0 -35 0 0 -180z m50 20 l0 -140 90 0 c83 0 90 -1 90
-20 0 -19 -7 -20 -105 -20 l-105 0 0 160 c0 136 2 160 15 160 13 0 15 -22 15
-140z"
          />
          <path
            d="M3385 314 c-27 -91 -52 -173 -53 -181 -3 -11 5 -14 34 -11 35 3 39 6
47 41 l9 37 72 0 73 0 12 -40 c11 -37 15 -40 46 -40 19 0 35 3 35 8 0 4 -24
84 -53 177 l-53 170 -59 3 -60 3 -50 -167z m201 -4 c49 -161 50 -170 29 -170
-8 0 -19 16 -25 40 l-11 40 -83 0 -83 0 -12 -40 c-8 -28 -17 -40 -30 -40 -16
0 -14 14 25 148 24 81 46 153 49 160 3 9 20 12 51 10 l47 -3 43 -145z"
          />
          <path
            d="M3450 348 c-12 -40 -23 -79 -25 -85 -3 -10 15 -13 71 -13 67 0 75 2
70 18 -3 9 -15 47 -26 85 -18 59 -24 67 -45 67 -21 0 -27 -8 -45 -72z m74 -6
c9 -32 16 -61 16 -65 0 -4 -21 -7 -46 -7 -39 0 -46 3 -41 18 3 9 11 38 18 65
7 26 18 47 25 47 6 0 19 -26 28 -58z"
          />
          <path
            d="M3700 300 l0 -180 35 0 34 0 3 105 3 105 88 -105 c68 -81 93 -105
112 -105 l25 0 0 180 0 180 -35 0 -34 0 -3 -107 -3 -107 -85 107 c-71 89 -90
107 -112 107 l-28 0 0 -180z m150 31 l100 -123 0 126 c0 105 2 126 15 126 13
0 15 -24 15 -160 0 -122 -3 -160 -12 -160 -7 1 -58 57 -113 126 l-100 125 -3
-125 c-2 -103 -5 -126 -17 -126 -13 0 -15 26 -15 161 0 137 2 160 15 157 8 -2
60 -59 115 -127z"
          />
          <path
            d="M4430 300 l0 -180 130 0 c117 0 130 2 130 18 0 9 3 27 6 40 6 22 5
22 -90 22 l-96 0 0 40 0 40 64 0 c58 0 64 2 70 23 14 54 11 57 -64 57 -63 0
-70 2 -70 20 0 19 7 20 89 20 l89 0 4 40 4 40 -133 0 -133 0 0 -180z m250 140
c0 -19 -7 -20 -95 -20 l-95 0 0 -40 0 -40 70 0 c63 0 70 -2 70 -20 0 -18 -7
-20 -70 -20 l-70 0 0 -60 0 -60 95 0 c88 0 95 -1 95 -20 0 -19 -7 -20 -115
-20 l-115 0 0 160 0 160 115 0 c108 0 115 -1 115 -20z"
          />
        </g>
        <!-- <g
          class="g-titre-svg"
          transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
          fill="none"
          stroke="#ffffff"
          stroke-width="5"
        >
          <path
            d="M245 648 c-55 -148 -101 -289 -97 -293 3 -3 21 -5 39 -5 29 0 36 5
        44 30 10 28 15 30 59 30 44 0 49 -2 59 -30 8 -26 15 -30 45 -30 19 0 37 3 39
        8 2 4 -19 72 -47 152 l-51 145 -43 3 c-28 2 -44 -1 -47 -10z m116 -95 c17 -54
        40 -119 50 -145 l19 -48 -34 0 c-30 0 -35 4 -41 30 -6 29 -8 30 -65 30 -57 0
        -59 -1 -65 -30 -6 -26 -11 -30 -39 -30 l-33 0 51 145 51 145 37 0 36 0 33 -97z"
          />
          <path
            d="M273 548 c-7 -24 -15 -51 -18 -60 -6 -15 -1 -18 33 -18 l40 0 -16 60
        c-9 33 -19 60 -22 60 -3 0 -11 -19 -17 -42z m31 -20 c10 -39 7 -48 -18 -48
        -23 0 -24 2 -13 35 12 40 22 44 31 13z"
          />
          <path
            d="M424 646 c-13 -33 0 -51 38 -54 l37 -3 3 -117 3 -117 35 0 35 0 3
        117 3 117 37 3 c34 3 37 6 37 33 l0 30 -113 3 c-92 2 -113 0 -118 -12z m226
        -21 c0 -22 -4 -25 -40 -25 l-40 0 0 -120 0 -120 -30 0 -30 0 0 120 0 120 -40
        0 c-36 0 -40 3 -40 25 l0 25 110 0 110 0 0 -25z"
          />
          <path
            d="M745 641 c-66 -34 -95 -105 -75 -182 45 -165 275 -127 274 46 0 56
        -23 107 -57 129 -40 27 -98 29 -142 7z m140 -17 c40 -26 62 -102 46 -162 -13
        -47 -57 -88 -104 -97 -48 -9 -114 20 -134 59 -24 45 -22 129 3 163 44 61 128
        77 189 37z"
          />
          <path
            d="M751 574 c-48 -61 -14 -164 54 -164 45 0 75 38 75 95 0 57 -30 95
        -75 95 -24 0 -39 -7 -54 -26z m103 -6 c9 -12 16 -41 16 -63 0 -56 -22 -85 -65
        -85 -43 0 -65 29 -65 85 0 56 22 85 65 85 24 0 39 -7 49 -22z"
          />
          <path
            d="M984 647 c-2 -7 -3 -76 -2 -152 l3 -140 35 0 35 0 5 79 5 78 24 -71
        c27 -79 39 -94 72 -89 18 2 28 18 50 83 l27 80 3 -80 4 -80 35 0 35 0 0 150 0
        150 -49 0 -49 0 -32 -95 -32 -95 -28 85 c-15 47 -32 91 -38 98 -13 16 -96 16
        -103 -1z m134 -107 l35 -105 36 108 35 107 43 0 43 0 0 -145 0 -145 -31 0 -30
        0 3 115 c2 63 1 111 -3 107 -3 -4 -20 -56 -39 -115 -32 -102 -36 -108 -60
        -105 -22 3 -29 14 -62 111 -20 60 -38 110 -40 112 -2 2 -1 -48 0 -111 l4 -114
        -31 0 -31 0 0 146 0 145 47 -3 46 -3 35 -105z"
          />
          <path
            d="M1475 648 c-3 -7 -4 -77 -3 -153 l3 -140 82 0 c89 1 115 12 157 69
        27 35 29 130 3 169 -30 46 -61 59 -152 64 -68 5 -86 3 -90 -9z m204 -25 c56
        -42 64 -154 15 -213 -30 -35 -77 -50 -156 -50 l-58 0 0 146 0 147 86 -6 c64
        -4 93 -10 113 -24z"
          />
          <path
            d="M1540 505 l0 -95 39 0 c55 0 91 38 91 97 0 59 -32 93 -90 93 l-40 0
        0 -95z m97 63 c52 -49 17 -148 -54 -148 l-33 0 0 85 0 85 32 0 c19 0 41 -9 55
        -22z"
          />
          <path
            d="M1774 647 c-2 -7 -3 -76 -2 -152 l3 -140 90 0 90 0 0 30 c0 29 -2 30
        -52 33 -48 3 -53 5 -53 27 0 21 5 24 48 27 45 3 47 4 47 33 0 29 -2 30 -47 33
        -43 3 -48 6 -48 27 0 21 5 24 48 27 42 3 47 6 51 28 2 17 18 -19 52 -114 27
        -77 52 -143 57 -148 5 -5 25 -8 43 -6 l34 3 51 145 c29 80 48 149 44 153 -4 5
        -22 7 -40 5 -26 -2 -35 -9 -41 -28 -4 -14 -18 -59 -30 -100 l-22 -75 -31 100
        -32 100 -127 3 c-98 2 -129 -1 -133 -11z m166 -22 c0 -23 -3 -25 -50 -25 l-50
        0 0 -35 0 -35 50 0 c47 0 50 -2 50 -25 0 -23 -3 -25 -50 -25 l-50 0 0 -35 0
        -35 55 0 c52 0 55 -1 55 -25 0 -25 -1 -25 -85 -25 l-85 0 0 145 0 145 80 0
        c79 0 80 0 80 -25z m120 -87 c18 -62 34 -114 35 -116 1 -1 17 49 36 113 l33
        115 32 0 33 0 -50 -141 c-27 -77 -49 -142 -49 -145 0 -2 -15 -4 -34 -4 -29 0
        -36 4 -46 33 -6 17 -29 83 -50 145 l-39 112 33 0 33 0 33 -112z"
          />
          <path
            d="M2444 623 c-44 -107 -113 -280 -116 -293 -2 -11 5 -15 26 -15 28 0
        32 6 101 165 40 91 71 168 68 173 -2 4 -17 7 -33 7 -25 0 -32 -6 -46 -37z m50
        -30 c-14 -32 -46 -106 -72 -165 -40 -93 -50 -108 -69 -108 -23 0 -23 0 -7 38
        8 20 39 94 67 165 49 119 53 127 79 127 l28 0 -26 -57z"
          />
          <path
            d="M2655 647 c-3 -7 -4 -76 -3 -152 l3 -140 35 0 35 0 3 57 3 57 47 3
        c45 3 47 4 47 33 0 29 -2 30 -47 33 -43 3 -48 6 -48 27 0 22 5 24 53 27 50 3
        52 4 52 33 l0 30 -88 3 c-66 2 -89 -1 -92 -11z m175 -22 c0 -24 -3 -25 -55
        -25 l-55 0 0 -35 0 -35 50 0 c47 0 50 -2 50 -25 0 -23 -3 -25 -50 -25 l-50 0
        0 -60 0 -60 -30 0 -30 0 0 145 0 145 85 0 c84 0 85 0 85 -25z"
          />
          <path
            d="M2867 654 c-11 -11 -8 -174 3 -215 15 -55 51 -82 109 -83 98 -2 134
        56 129 208 l-3 91 -35 0 -35 0 -3 -104 c-3 -110 -10 -131 -47 -131 -37 0 -44
        21 -47 131 l-3 104 -30 3 c-17 2 -34 0 -38 -4z m63 -105 c0 -119 8 -139 55
        -139 47 0 55 20 55 139 l0 101 30 0 30 0 -1 -92 c0 -51 -5 -109 -12 -128 -25
        -74 -143 -91 -191 -27 -17 24 -21 46 -24 138 l-4 109 31 0 31 0 0 -101z"
          />
          <path
            d="M3155 648 c-3 -8 -4 -77 -3 -153 l3 -140 85 0 85 0 0 30 c0 29 -2 30
        -47 33 l-47 3 -3 117 -3 117 -33 3 c-20 2 -34 -2 -37 -10z m65 -118 l0 -120
        50 0 c47 0 50 -2 50 -25 0 -25 -1 -25 -80 -25 l-80 0 0 145 0 145 30 0 30 0 0
        -120z"
          />
          <path
            d="M3355 648 c-3 -8 -4 -77 -3 -153 l3 -140 85 0 85 0 0 30 c0 29 -2 30
        -47 33 l-47 3 -3 117 -3 117 -33 3 c-20 2 -34 -2 -37 -10z m65 -118 l0 -120
        50 0 c47 0 50 -2 50 -25 0 -25 -1 -25 -80 -25 l-80 0 0 145 0 145 30 0 30 0 0
        -120z"
          />
          <path
            d="M3723 652 c-33 -10 -62 -38 -69 -66 -9 -37 16 -77 66 -106 25 -14 47
        -34 48 -44 4 -19 -7 -19 -93 -4 -23 4 -34 -34 -15 -57 26 -32 122 -28 159 6
        49 46 33 107 -39 149 -25 14 -47 34 -48 44 -3 16 3 18 50 14 49 -3 53 -1 56
        21 2 13 0 27 -5 32 -12 12 -84 19 -110 11z m76 -8 c23 -5 31 -12 31 -28 0 -20
        -5 -22 -52 -21 -46 0 -53 -3 -56 -20 -2 -15 10 -28 50 -50 40 -23 55 -38 62
        -63 18 -64 -52 -114 -133 -95 -30 7 -37 14 -39 37 -3 25 -1 28 15 21 10 -5 36
        -9 58 -9 34 -1 40 2 43 21 3 17 -8 27 -50 50 -66 35 -84 77 -51 121 27 36 65
        47 122 36z"
          />
          <path
            d="M3854 646 c-13 -33 0 -51 38 -54 l37 -3 3 -117 3 -117 35 0 35 0 3
        117 3 117 37 3 c34 3 37 6 37 33 l0 30 -113 3 c-92 2 -113 0 -118 -12z m226
        -21 c0 -22 -4 -25 -40 -25 l-40 0 0 -120 0 -120 -30 0 -30 0 0 120 0 120 -40
        0 c-36 0 -40 3 -40 25 l0 25 110 0 110 0 0 -25z"
          />
          <path
            d="M4185 648 c-55 -148 -101 -289 -97 -293 3 -3 21 -5 39 -5 29 0 36 5
        44 30 10 28 15 30 59 30 44 0 49 -2 59 -30 8 -26 15 -30 45 -30 19 0 37 3 39
        8 2 4 -19 72 -47 152 l-51 145 -43 3 c-28 2 -44 -1 -47 -10z m116 -95 c17 -54
        40 -119 50 -145 l19 -48 -34 0 c-30 0 -35 4 -41 30 -6 29 -8 30 -65 30 -57 0
        -59 -1 -65 -30 -6 -26 -11 -30 -39 -30 l-33 0 51 145 51 145 37 0 36 0 33 -97z"
          />
          <path
            d="M4213 548 c-7 -24 -15 -51 -18 -60 -6 -15 -1 -18 33 -18 l40 0 -16
        60 c-9 33 -19 60 -22 60 -3 0 -11 -19 -17 -42z m31 -20 c10 -39 7 -48 -18 -48
        -23 0 -24 2 -13 35 12 40 22 44 31 13z"
          />
          <path
            d="M4463 641 c-75 -35 -108 -118 -78 -196 23 -63 65 -90 136 -90 63 0
        92 17 87 49 -3 19 -9 21 -60 18 -72 -5 -98 17 -98 81 0 64 31 90 102 85 49 -3
        53 -1 56 21 5 31 -6 40 -58 46 -33 4 -57 0 -87 -14z m106 3 c23 -5 31 -12 31
        -29 0 -20 -4 -21 -54 -17 -50 4 -57 2 -80 -23 -36 -39 -36 -106 0 -142 23 -23
        31 -25 80 -20 52 5 55 4 52 -15 -6 -40 -109 -48 -163 -12 -25 16 -55 80 -55
        117 0 12 9 41 20 65 15 33 29 47 62 62 47 21 60 23 107 14z"
          />
          <path
            d="M4645 648 c-3 -8 -4 -77 -3 -153 l3 -140 35 0 35 0 3 58 3 59 40 -56
        c37 -52 43 -56 79 -56 21 0 41 4 44 9 3 4 -17 37 -44 72 -27 35 -50 67 -50 70
        0 4 20 33 45 64 24 32 42 62 39 66 -3 5 -22 9 -42 9 -33 0 -40 -5 -72 -52
        l-35 -51 -5 54 c-5 52 -6 54 -38 57 -20 2 -34 -2 -37 -10z m65 -69 l0 -71 41
        62 c45 67 54 73 93 68 l26 -3 -45 -57 c-25 -32 -45 -61 -45 -64 0 -4 10 -21
        23 -38 13 -17 36 -48 51 -68 l28 -38 -39 0 c-37 0 -42 4 -86 67 l-47 67 0 -72
        0 -72 -30 0 -30 0 0 145 0 145 30 0 30 0 0 -71z"
          />
        </g> -->
      </svg>
    </div>
    <div class="bouton">
      <div
        class="titre"
        id="titre"
        @mouseover="accelereRalanti('a')"
        @mouseleave="accelereRalanti('r')"
        @click="OuvreAcceuil"
      >
        DECOUVRIR
      </div>
      <div
        class="titre"
        id="titre"
        v-on:mouseover="accelereRalanti('m')"
        v-on:mouseleave="accelereRalanti('r')"
        @click="ouvreContact"
      >
        CONTACT
      </div>
    </div>

    <!--<div
      class="techno"
      v-on:mouseover="accelereRalanti('b')"
      v-on:mouseleave="accelereRalanti('r')"
    >
      <div class="techno-txt">
        Vue js 3
      </div>
      <div class="techno-txt">
        Node js
      </div>
      <div class="techno-txt">
        html css js
      </div>
      <div class="techno-txt">
        Site Web / e-Commerce
      </div>
      <div class="techno-txt">
        API
      </div>
      <div class="techno-txt">
        Base SQL et NoSQL
      </div>
      <div class="techno-txt">
        Dev Ops (c# et vb.net)
      </div>
    </div>-->

    <div class="universe">
      <svg
        class="universe-svg"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="200.000000pt"
        height="30.000000pt"
        viewBox="0 0 1000.000000 150.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)"
          fill="none"
          stroke="#ffffff"
          stroke-width="8"
        >
          <path
            d="M5645 1170 c-56 -35 -105 -158 -105 -261 0 -52 -8 -59 -65 -59 -32 0
-65 -21 -65 -40 0 -22 44 -39 85 -33 l42 5 6 -68 c4 -38 7 -114 7 -169 0 -105
12 -147 43 -153 10 -2 25 2 33 9 14 11 15 38 9 205 l-8 193 38 7 c20 3 62 7
93 8 52 1 57 3 66 29 8 21 6 31 -7 44 -14 15 -24 15 -84 4 -37 -7 -79 -15 -91
-18 -23 -6 -24 -5 -17 53 4 33 13 83 21 111 24 89 90 109 120 37 8 -19 21 -37
29 -41 23 -8 45 16 45 51 -1 84 -118 136 -195 86z m150 -21 c31 -27 36 -37 33
-68 -4 -50 -32 -54 -52 -7 -20 48 -56 65 -91 42 -15 -10 -33 -33 -41 -52 -15
-37 -40 -192 -31 -200 3 -3 46 3 96 12 101 20 118 15 107 -30 -6 -24 -11 -26
-54 -24 -26 1 -69 -2 -97 -6 l-50 -7 9 -190 c4 -104 6 -196 2 -204 -9 -25 -45
-18 -56 11 -6 14 -10 67 -10 117 0 51 -3 128 -6 173 l-7 81 -44 -8 c-43 -8
-83 1 -83 19 0 17 26 32 55 32 68 0 72 5 78 88 11 155 73 252 162 252 35 0 51
-6 80 -31z"
          />
          <path
            d="M8060 1170 c-6 -11 -9 -24 -6 -28 3 -5 10 -138 16 -297 6 -181 14
-293 21 -302 6 -7 19 -13 29 -13 9 0 25 -9 35 -20 26 -29 46 -25 113 24 116
86 202 213 210 312 4 46 1 56 -21 78 -59 59 -167 11 -258 -115 l-44 -60 -6
203 c-3 112 -6 204 -7 205 -1 1 -9 9 -18 17 -21 22 -51 20 -64 -4z m64 -12
c13 -18 16 -57 16 -213 0 -205 7 -239 35 -185 25 48 113 139 157 161 107 55
168 -15 123 -141 -23 -64 -99 -164 -168 -220 -70 -57 -102 -70 -123 -48 -19
19 -10 42 24 59 100 49 190 151 214 241 22 84 -27 91 -108 16 -46 -43 -118
-171 -130 -233 -7 -39 -25 -58 -51 -53 -21 4 -29 72 -33 288 -1 74 -5 178 -9
230 -4 52 -5 101 -3 108 7 20 40 14 56 -10z m273 -324 c-13 -82 -97 -190 -186
-239 l-43 -25 6 28 c11 46 71 165 101 201 34 39 82 71 109 71 16 0 18 -5 13
-36z"
          />
          <path
            d="M8793 1162 c-6 -4 -16 -19 -22 -33 -11 -31 -18 -554 -7 -587 11 -32
39 -44 71 -32 24 9 26 14 20 38 -12 45 -16 288 -7 442 5 81 5 153 0 163 -9 18
-34 22 -55 9z m49 -14 c2 -7 1 -67 -3 -133 -11 -191 -10 -404 3 -446 12 -43
12 -44 -17 -53 -56 -16 -63 23 -56 350 5 200 9 265 20 277 16 20 47 22 53 5z"
          />
          <path
            d="M2402 1128 c-32 -32 11 -82 65 -76 36 4 42 32 12 64 -24 26 -58 31
-77 12z m70 -15 c27 -25 23 -53 -8 -53 -14 0 -34 5 -45 10 -19 10 -26 40 -12
53 12 12 46 7 65 -10z"
          />
          <path
            d="M7842 1128 c-32 -32 11 -82 65 -76 36 4 42 32 12 64 -24 26 -58 31
-77 12z m70 -15 c27 -25 23 -53 -8 -53 -14 0 -34 5 -45 10 -19 10 -26 40 -12
53 12 12 46 7 65 -10z"
          />
          <path
            d="M8522 1128 c-32 -32 11 -82 65 -76 36 4 42 32 12 64 -24 26 -58 31
-77 12z m70 -15 c27 -25 23 -53 -8 -53 -14 0 -34 5 -45 10 -19 10 -26 40 -12
53 12 12 46 7 65 -10z"
          />
          <path
            d="M8922 1128 c-32 -32 11 -82 65 -76 36 4 42 32 12 64 -24 26 -58 31
-77 12z m70 -15 c27 -25 23 -53 -8 -53 -14 0 -34 5 -45 10 -19 10 -26 40 -12
53 12 12 46 7 65 -10z"
          />
          <path
            d="M337 1093 c-35 -6 -44 -25 -92 -195 l-39 -136 -59 -13 c-73 -15 -107
-40 -91 -66 9 -14 23 -18 62 -16 l51 2 -40 -113 c-21 -62 -39 -120 -39 -128 0
-27 28 -42 57 -32 23 8 32 26 72 154 l47 145 59 12 c33 6 80 13 105 15 l45 3
18 -104 c24 -135 38 -171 70 -171 49 0 52 19 22 161 -15 72 -25 131 -23 132
53 28 67 49 48 72 -10 12 -23 15 -46 10 -30 -5 -33 -3 -39 27 -13 61 -66 188
-92 218 -24 30 -35 32 -96 23z m85 -20 c25 -28 47 -77 77 -171 l29 -87 38 0
c32 0 39 -3 39 -20 0 -12 -11 -25 -27 -33 -16 -7 -28 -17 -28 -22 0 -5 12 -64
26 -131 14 -67 23 -128 20 -135 -3 -8 -17 -14 -30 -14 -22 0 -27 7 -40 53 -8
28 -20 86 -27 128 -6 42 -15 82 -18 88 -6 9 -35 7 -114 -5 l-107 -17 -47 -146
c-25 -80 -51 -149 -57 -153 -19 -13 -46 -9 -53 8 -3 9 13 71 36 138 22 66 41
124 41 128 0 4 -15 2 -32 -3 -48 -13 -78 -11 -85 7 -9 23 8 34 83 53 l67 17
43 144 c53 184 51 178 82 183 55 8 69 7 84 -10z"
          />
          <path
            d="M365 1020 c-12 -20 -85 -244 -80 -248 2 -2 42 1 89 8 69 11 86 17 86
30 0 37 -70 220 -85 220 -2 0 -7 -5 -10 -10z m64 -129 c12 -35 21 -70 21 -76
0 -10 -107 -35 -149 -35 -5 0 9 55 31 122 l41 121 17 -34 c10 -19 27 -63 39
-98z"
          />
          <path
            d="M1441 1081 c-27 -27 -36 -105 -36 -296 0 -124 4 -173 18 -217 61
-197 193 -156 341 106 l46 81 0 -75 c0 -91 14 -145 45 -176 29 -29 39 -30 65
-4 20 20 20 20 -4 64 -22 41 -24 60 -31 265 -6 174 -10 225 -22 236 -34 35
-59 0 -68 -95 -11 -122 -87 -284 -183 -391 -60 -67 -84 -66 -108 4 -23 66 -26
306 -5 414 11 59 12 75 1 88 -17 20 -36 19 -59 -4z m53 -31 c-1 -19 -7 -75
-14 -125 -16 -114 -7 -284 17 -351 27 -72 55 -72 120 0 100 109 177 272 188
398 8 82 23 110 49 89 11 -9 16 -63 21 -232 7 -201 10 -225 30 -265 20 -39 21
-47 8 -59 -19 -20 -29 -19 -52 4 -28 28 -41 84 -41 179 0 45 -3 82 -7 82 -5 0
-25 -33 -47 -74 -89 -170 -186 -261 -253 -239 -66 22 -103 141 -103 327 0 141
16 265 38 289 25 28 47 17 46 -23z"
          />
          <path
            d="M9297 1072 c-13 -14 -17 -39 -17 -109 l0 -91 -72 -12 c-120 -19 -141
-36 -98 -75 18 -16 24 -16 92 -3 l73 14 8 -124 c8 -140 20 -176 58 -180 40 -5
53 19 41 75 -6 27 -13 92 -16 146 -5 111 -17 97 104 117 36 7 75 18 88 26 21
14 22 13 22 -13 0 -35 36 -165 55 -204 38 -73 93 -88 147 -40 l34 29 -4 -134
c-4 -112 -2 -138 12 -159 18 -28 43 -32 71 -11 17 13 18 17 5 70 -12 49 -11
80 5 239 31 305 31 305 0 319 -38 17 -54 -4 -61 -82 -7 -69 -38 -146 -78 -195
-20 -24 -28 -28 -42 -19 -22 13 -42 69 -60 169 -9 51 -21 87 -34 99 -19 19
-20 19 -40 1 -19 -17 -21 -17 -40 0 -17 16 -22 16 -42 3 -24 -15 -104 -38
-133 -38 -15 0 -17 12 -15 88 1 48 2 93 1 100 -2 18 -46 14 -64 -6z m55 -99
l-4 -101 74 16 c40 9 79 22 87 29 17 17 30 13 48 -14 21 -31 -3 -49 -84 -62
-37 -6 -80 -14 -95 -17 l-27 -5 5 -102 c3 -56 10 -123 16 -149 11 -55 6 -68
-28 -68 -35 0 -44 31 -51 182 -5 111 -8 128 -22 124 -85 -22 -142 -28 -156
-16 -8 7 -15 19 -15 26 0 15 7 17 118 35 l72 12 0 97 c0 101 8 123 45 118 19
-3 20 -9 17 -105z m556 -32 c16 -10 15 -34 -13 -308 -16 -158 -17 -190 -5
-239 15 -59 8 -73 -32 -74 -34 0 -41 37 -36 190 l6 145 -38 -37 c-90 -91 -164
-10 -200 220 -9 55 -9 68 5 81 24 24 44 -4 56 -80 18 -119 52 -199 85 -199 19
0 64 59 89 115 12 28 25 79 28 113 9 84 19 97 55 73z"
          />
          <path
            d="M6235 1035 c-136 -37 -202 -74 -189 -108 11 -29 39 -28 100 3 32 16
64 30 70 30 8 0 13 -73 18 -232 6 -191 10 -236 23 -250 20 -22 37 -23 54 -1
11 12 13 45 11 138 l-4 122 88 42 c112 54 164 105 164 162 0 49 -30 82 -86 98
-53 15 -188 13 -249 -4z m248 -6 c54 -15 77 -41 77 -89 0 -56 -50 -104 -163
-155 l-88 -40 3 -129 c3 -113 1 -130 -14 -140 -13 -8 -21 -7 -32 4 -12 12 -16
65 -22 253 -6 199 -9 237 -22 237 -8 0 -43 -14 -79 -32 -59 -29 -65 -30 -80
-15 -23 23 -5 41 65 67 130 49 266 64 355 39z"
          />
          <path
            d="M6300 894 c0 -94 0 -95 23 -89 45 14 139 69 158 94 45 57 4 91 -108
91 l-73 0 0 -96z m182 68 c31 -31 -44 -106 -143 -141 l-29 -10 0 86 0 85 80
-3 c45 -3 84 -9 92 -17z"
          />
          <path
            d="M3004 988 c-41 -49 -110 -202 -137 -302 l-23 -88 -63 139 c-78 173
-103 213 -135 213 -26 0 -69 -45 -63 -64 2 -6 14 -15 26 -21 16 -7 39 -44 75
-120 118 -252 139 -290 167 -293 37 -4 48 15 69 118 35 169 89 303 149 368 33
35 31 62 -5 76 -26 10 -31 8 -60 -26z m71 6 c14 -15 13 -19 -15 -50 -59 -68
-123 -228 -150 -374 -17 -93 -25 -110 -55 -110 -24 0 -32 12 -82 118 -129 274
-133 283 -158 292 -29 11 -30 18 -9 48 41 60 79 15 180 -215 32 -72 60 -131
61 -129 2 1 14 46 27 99 38 149 133 337 171 337 7 0 21 -7 30 -16z"
          />
          <path
            d="M2430 955 c-9 -11 -10 -60 -1 -222 12 -217 21 -253 60 -253 36 0 44
23 32 99 -6 38 -11 132 -11 208 0 109 -3 143 -16 161 -17 25 -46 29 -64 7z
m58 -12 c6 -10 12 -99 16 -198 3 -99 7 -196 9 -215 2 -29 -2 -36 -20 -38 -13
-2 -26 4 -32 16 -11 21 -36 415 -27 438 8 20 43 18 54 -3z"
          />
          <path
            d="M3283 949 c-124 -61 -205 -250 -155 -362 27 -60 69 -87 140 -87 66 0
119 26 161 79 55 69 87 160 62 175 -18 12 -37 -5 -65 -59 -40 -78 -86 -115
-139 -115 -23 0 -47 4 -54 8 -21 13 -35 66 -28 107 6 39 7 40 75 62 118 38
191 102 175 153 -18 58 -98 76 -172 39z m127 1 c32 -17 43 -48 30 -80 -16 -38
-73 -74 -162 -104 -66 -23 -74 -28 -81 -57 -21 -80 16 -139 88 -139 55 0 100
36 148 118 18 31 38 57 45 60 33 11 -5 -102 -54 -162 -43 -51 -92 -76 -152
-76 -72 0 -109 21 -134 77 -35 80 -8 197 67 289 56 68 151 103 205 74z"
          />
          <path
            d="M3292 890 c-36 -22 -78 -83 -61 -89 17 -5 97 29 130 56 23 20 27 28
18 39 -16 20 -48 17 -87 -6z m83 1 c10 -17 -118 -91 -132 -77 -8 8 24 47 54
67 30 20 68 25 78 10z"
          />
          <path
            d="M3755 952 c-35 -22 -82 -75 -99 -111 -10 -23 -11 -20 -16 33 -6 66
-26 90 -57 70 -16 -10 -18 -32 -18 -217 0 -197 1 -207 21 -228 18 -17 25 -19
43 -10 19 11 21 20 21 102 0 83 3 95 36 162 44 87 80 137 100 137 7 0 14 -5
14 -10 0 -19 46 -33 69 -21 28 15 27 52 -2 79 -33 31 -76 37 -112 14z m107
-19 c25 -22 23 -60 -3 -68 -14 -5 -28 0 -43 14 -12 12 -27 21 -33 21 -19 0
-63 -60 -104 -141 -37 -74 -39 -84 -39 -168 0 -74 -3 -90 -17 -95 -9 -4 -23 0
-32 9 -14 13 -16 48 -16 223 l0 207 25 0 c24 0 25 -3 30 -75 l5 -75 19 35 c67
124 147 168 208 113z"
          />
          <path
            d="M4098 956 c-48 -17 -119 -59 -141 -84 -24 -27 -36 -72 -25 -98 15
-32 59 -53 165 -79 107 -27 129 -41 119 -78 -10 -42 -132 -88 -151 -57 -17 27
-95 -12 -95 -47 0 -71 203 -49 277 29 58 62 68 107 33 151 -20 25 -64 42 -212
82 -45 12 -58 20 -58 35 0 38 96 88 145 76 17 -4 31 0 45 14 54 54 -9 89 -102
56z m110 -17 c5 -25 -26 -50 -53 -43 -50 13 -155 -46 -155 -87 0 -24 19 -33
120 -58 47 -12 101 -30 120 -40 128 -66 -16 -239 -191 -229 -63 4 -84 25 -55
57 20 21 66 29 66 11 0 -33 139 11 160 50 27 51 -2 78 -105 100 -142 30 -191
64 -175 121 12 41 25 55 78 86 90 53 183 68 190 32z"
          />
          <path
            d="M4513 949 c-124 -61 -205 -250 -155 -362 27 -60 69 -87 140 -87 66 0
119 26 161 79 55 69 87 160 62 175 -18 12 -37 -5 -65 -59 -40 -78 -86 -115
-139 -115 -23 0 -47 4 -54 8 -21 13 -35 66 -28 107 6 39 7 40 75 62 118 38
191 102 175 153 -18 58 -98 76 -172 39z m127 1 c32 -17 43 -48 30 -80 -16 -38
-73 -74 -162 -104 -66 -23 -74 -28 -81 -57 -21 -80 16 -139 88 -139 55 0 100
36 148 118 18 31 38 57 45 60 33 11 -5 -102 -54 -162 -43 -51 -92 -76 -152
-76 -72 0 -109 21 -134 77 -35 80 -8 197 67 289 56 68 151 103 205 74z"
          />
          <path
            d="M4522 890 c-36 -22 -78 -83 -61 -89 17 -5 97 29 130 56 23 20 27 28
18 39 -16 20 -48 17 -87 -6z m83 1 c10 -17 -118 -91 -132 -77 -8 8 24 47 54
67 30 20 68 25 78 10z"
          />
          <path
            d="M7188 956 c-48 -17 -119 -59 -141 -84 -24 -27 -36 -72 -25 -98 15
-32 59 -53 165 -79 107 -27 129 -41 119 -78 -10 -42 -132 -88 -151 -57 -17 27
-95 -12 -95 -47 0 -71 203 -49 277 29 58 62 68 107 33 151 -20 25 -64 42 -212
82 -45 12 -58 20 -58 35 0 38 96 88 145 76 17 -4 31 0 45 14 54 54 -9 89 -102
56z m110 -17 c5 -25 -26 -50 -53 -43 -50 13 -155 -46 -155 -87 0 -24 19 -33
120 -58 47 -12 101 -30 120 -40 128 -66 -16 -239 -191 -229 -63 4 -84 25 -55
57 20 21 66 29 66 11 0 -33 139 11 160 50 27 51 -2 78 -105 100 -142 30 -191
64 -175 121 12 41 25 55 78 86 90 53 183 68 190 32z"
          />
          <path
            d="M7598 956 c-48 -17 -119 -59 -141 -84 -24 -27 -36 -72 -25 -98 15
-32 59 -53 165 -79 107 -27 129 -41 119 -78 -10 -42 -132 -88 -151 -57 -17 27
-95 -12 -95 -47 0 -71 203 -49 277 29 58 62 68 107 33 151 -20 25 -64 42 -212
82 -45 12 -58 20 -58 35 0 38 96 88 145 76 17 -4 31 0 45 14 54 54 -9 89 -102
56z m110 -17 c5 -25 -26 -50 -53 -43 -50 13 -155 -46 -155 -87 0 -24 19 -33
120 -58 47 -12 101 -30 120 -40 128 -66 -16 -239 -191 -229 -63 4 -84 25 -55
57 20 21 66 29 66 11 0 -33 139 11 160 50 27 51 -2 78 -105 100 -142 30 -191
64 -175 121 12 41 25 55 78 86 90 53 183 68 190 32z"
          />
          <path
            d="M7870 955 c-9 -11 -10 -60 -1 -222 12 -217 21 -253 60 -253 36 0 44
23 32 99 -6 38 -11 132 -11 208 0 109 -3 143 -16 161 -17 25 -46 29 -64 7z
m58 -12 c6 -10 12 -99 16 -198 3 -99 7 -196 9 -215 2 -29 -2 -36 -20 -38 -13
-2 -26 4 -32 16 -11 21 -36 415 -27 438 8 20 43 18 54 -3z"
          />
          <path
            d="M8550 955 c-9 -11 -10 -60 -1 -222 12 -217 21 -253 60 -253 36 0 44
23 32 99 -6 38 -11 132 -11 208 0 109 -3 143 -16 161 -17 25 -46 29 -64 7z
m58 -12 c6 -10 12 -99 16 -198 3 -99 7 -196 9 -215 2 -29 -2 -36 -20 -38 -13
-2 -26 4 -32 16 -11 21 -36 415 -27 438 8 20 43 18 54 -3z"
          />
          <path
            d="M8950 955 c-9 -11 -10 -60 -1 -222 12 -217 21 -253 60 -253 36 0 44
23 32 99 -6 38 -11 132 -11 208 0 109 -3 143 -16 161 -17 25 -46 29 -64 7z
m58 -12 c6 -10 12 -99 16 -198 3 -99 7 -196 9 -215 2 -29 -2 -36 -20 -38 -13
-2 -26 4 -32 16 -11 21 -36 415 -27 438 8 20 43 18 54 -3z"
          />
          <path
            d="M696 939 c-20 -15 -26 -28 -22 -43 3 -11 13 -95 22 -186 9 -91 21
-171 26 -177 16 -20 42 -15 64 10 11 12 41 76 67 142 27 66 57 131 68 145 l21
25 -6 -181 c-3 -128 -1 -185 7 -193 19 -19 56 -12 73 14 15 23 16 44 7 205
-12 201 -21 230 -72 230 -40 0 -89 -59 -132 -158 l-34 -77 -7 45 c-3 25 -7 72
-7 105 -1 60 -16 105 -37 112 -6 2 -23 -6 -38 -18z m56 -25 c4 -16 11 -82 15
-147 l8 -119 43 100 c53 121 92 172 132 172 20 0 33 -7 41 -22 6 -12 16 -100
22 -196 11 -198 6 -226 -41 -220 l-27 3 4 188 c2 128 0 187 -7 187 -20 0 -52
-58 -101 -179 -54 -136 -66 -153 -95 -149 -19 3 -22 14 -33 118 -7 63 -17 149
-22 190 -10 71 -9 76 11 93 27 23 41 18 50 -19z"
          />
          <path
            d="M1996 939 c-20 -15 -26 -28 -22 -43 3 -11 13 -95 22 -186 9 -91 21
-171 26 -177 16 -20 42 -15 64 10 11 12 41 76 67 142 27 66 57 131 68 145 l21
25 -6 -181 c-3 -128 -1 -185 7 -193 19 -19 56 -12 73 14 15 23 16 44 7 205
-12 201 -21 230 -72 230 -40 0 -89 -59 -132 -158 l-34 -77 -7 45 c-3 25 -7 72
-7 105 -1 60 -16 105 -37 112 -6 2 -23 -6 -38 -18z m56 -25 c4 -16 11 -82 15
-147 l8 -119 43 100 c53 121 92 172 132 172 20 0 33 -7 41 -22 6 -12 16 -100
22 -196 11 -198 6 -226 -41 -220 l-27 3 4 188 c2 128 0 187 -7 187 -20 0 -52
-58 -101 -179 -54 -136 -66 -153 -95 -149 -19 3 -22 14 -33 118 -7 63 -17 149
-22 190 -10 71 -9 76 11 93 27 23 41 18 50 -19z"
          />
          <path
            d="M5143 916 c-52 -44 -113 -199 -113 -286 0 -73 64 -140 134 -140 86 0
199 82 226 164 19 57 6 95 -58 170 -50 59 -60 66 -84 62 -22 -4 -26 -2 -21 11
6 16 -20 43 -43 43 -7 0 -25 -11 -41 -24z m61 8 c22 -9 21 -15 -18 -73 -46
-68 -76 -151 -76 -211 0 -42 4 -52 25 -66 69 -45 185 33 185 124 0 34 -13 51
-88 114 -39 32 -40 45 -6 58 35 14 54 12 60 -4 3 -8 25 -34 49 -60 75 -77 70
-152 -14 -236 -67 -66 -148 -87 -216 -54 -38 18 -65 66 -65 116 0 82 62 243
108 279 27 21 32 22 56 13z m45 -140 c48 -45 61 -63 61 -87 0 -83 -114 -158
-174 -114 -27 20 -18 114 18 195 16 34 30 62 31 62 2 0 30 -25 64 -56z"
          />
          <path
            d="M6723 916 c-52 -44 -113 -199 -113 -286 0 -73 64 -140 134 -140 86 0
199 82 226 164 19 57 6 95 -58 170 -50 59 -60 66 -84 62 -22 -4 -26 -2 -21 11
6 16 -20 43 -43 43 -7 0 -25 -11 -41 -24z m61 8 c22 -9 21 -15 -18 -73 -46
-68 -76 -151 -76 -211 0 -42 4 -52 25 -66 69 -45 185 33 185 124 0 34 -13 51
-88 114 -39 32 -40 45 -6 58 35 14 54 12 60 -4 3 -8 25 -34 49 -60 75 -77 70
-152 -14 -236 -67 -66 -148 -87 -216 -54 -38 18 -65 66 -65 116 0 82 62 243
108 279 27 21 32 22 56 13z m45 -140 c48 -45 61 -63 61 -87 0 -83 -114 -158
-174 -114 -27 20 -18 114 18 195 16 34 30 62 31 62 2 0 30 -25 64 -56z"
          />
        </g>
      </svg>
    </div>
    <div class="couleur">
      <div
        class="btn"
        style="background-color: tomato"
        @click="colorChange('red')"
      ></div>
      <div
        class="btn"
        style="background-color: palegreen"
        @click="colorChange('green')"
      ></div>
      <div
        class="btn"
        style="background-color: lightblue"
        @click="colorChange('blue')"
      ></div>
      <div
        class="btn"
        style="background-color: violet"
        @click="colorChange('violet')"
      ></div>
      <div
        class="btn"
        style="background-color: yellow"
        @click="colorChange('yellow')"
      ></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as THREE from "three";
import gsap from "gsap";
import router from "../router";
import VueCookies from "vue-cookies";
import * as FCT from "../js/functions";
import * as CANNON from "cannon-es";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default {
  name: "Home",
  data() {
    return {
      plus: 0.02,
      z_value: 300,
      vitesse: 2,
      windowHalfX: 0,
      windowHalfY: 0,
      fog: 0.002,
      color: 0xc42020,
    };
  },
  components: {},
  mounted() {
    const C1 = VueCookies.get("atomColor");
    //console.log(C1);

    this.color = FCT.trouveColor(C1);
    //console.log(this.color);

    this.init3D();
    this.animate();

    //this.initCANNON()

    window.addEventListener("resize", this.onWindowResize);
    window.addEventListener("popstate", this.stopAnimation);

    var tl = gsap.timeline({ paused: true, delay: 1 });
    tl.to("#entete", { opacity: 1, duration: 0.1, ease: "power2.out" });
    tl.to("path", { strokeDashoffset: 0, duration: 6, stagger: 0.2 });
    tl.to("g", { strokeWidth: 15, duration: 0.8 }, "-=7");
    tl.to(
      ".bouton",
      {
        opacity: 1,
        top: "45vh",
        duration: 1,
        ease: "power2.out",
        onComplete: () => {
          window.addEventListener("mousemove", this.modifGravity);
          window.addEventListener("touchmove", this.modifGravity);
          this.controls.minDistance = 6;
        },
      },
      "-=8.5"
    );
    /*tl.to(
      ".techno-txt",
      {
        opacity: 0.75,
        top: "45vh",
        duration: 1,
        ease: "power2.out",
        stagger: 0.5,
      },
      "-=10"
    );*/
    tl.to(
      ".g-titre-svg",
      { fill: "#978887", duration: 1, ease: "power2.out" },
      "-=8"
    );
    tl.play();
  },
  methods: {
    initCANNON() {
      this.scene2 = new THREE.Scene();
      let cannon = document.getElementById("cannon");
      let xx = window.innerWidth * 0.65;
      let yy = window.innerHeight * 0.2;
      this.camera2 = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      //console.log("cannon: " + xx);
      this.camera2.lookAt(0, 0, 0);
      this.renderer2 = new THREE.WebGLRenderer({ antialias: true });
      this.renderer2.setSize(xx, yy);
      this.renderer2.setClearColor("transparent", 1);
      this.renderer2.shadowMap.enabled = true;
      this.renderer2.toneMapping = THREE.ACESFilmicToneMapping;
      cannon.appendChild(this.renderer2.domElement);
      //this.scene2.add(new THREE.AxesHelper(10));

      /*
      var geometry = new THREE.TorusGeometry( 1, 0.5, 16, 100 );
      var material = new THREE.MeshBasicMaterial( { color: 0x000fff } );
      this.cube = new THREE.Mesh( geometry, material );
      this.scene.add( this.cube );*/

      this.camera2.position.z = 20;
      this.camera2.position.x = 0;
      this.camera2.position.y = 1;

      let solMat = new THREE.MeshPhongMaterial({
        color: 0x0000ff,
        side: THREE.DoubleSide,
        //transparent: true,
        reflectivity: 0.8,
      });
      let solGeo = new THREE.BoxGeometry(xx * 0.2, 2, 20, 10);
      //solGeo.rotateX(-Math.PI / 2)

      let sol = new THREE.Mesh(solGeo, solMat);
      sol.receiveShadow = true;
      sol.rotation.x = -Math.PI;
      //sol.rotation.z = 0.174533
      //this.scene2.add(sol)

      let l1 = new THREE.PointLight(0xffffff, 1, 1500);
      l1.position.set(0, 20, 10);
      this.scene2.add(l1);

      let tabBalle = [];

      const diamBalle = xx * 0.004; //xx * 0.005
      const xDepart = -4 * diamBalle;
      const Ecart = xx * 0.001;
      let balleGeo = new THREE.SphereGeometry(diamBalle / 2, 50, 50);
      let balleMat = new THREE.MeshPhongMaterial({
        color: 0xff00ff,
        side: THREE.DoubleSide,
        //transparent: true,
        reflectivity: 0.8,
      });
      for (let i = 0; i < 8; i++) {
        let balle = new THREE.Mesh(balleGeo, balleMat);
        balle.position.x = xDepart + i * (diamBalle + 1);
        tabBalle.push(balle);
        this.scene2.add(tabBalle[i]);
        //console.log(tabBalle[i].position);
      }

      this.renderer2.render(this.scene2, this.camera2);
    },

    init3D() {
      this.scene = new THREE.Scene();
      this.scene.fog = new THREE.FogExp2(0x000000, this.fog);
      this.camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      this.camera.lookAt(0, 0, 0);
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      let home = document.getElementById("home");
      home.appendChild(this.renderer.domElement);
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;
      //this.scene.add(new THREE.AxesHelper(5));

      /*
      var geometry = new THREE.TorusGeometry( 1, 0.5, 16, 100 );
      var material = new THREE.MeshBasicMaterial( { color: 0x000fff } );
      this.cube = new THREE.Mesh( geometry, material );
      this.scene.add( this.cube );*/

      this.camera.position.z = 10; //this.z_value;
      this.camera.position.x = 1;
      this.camera.position.y = 2;

      var geometry = new THREE.BufferGeometry();
      //var geometry = new THREE.BoxGeometry(5,5,5,2,2,2)
      //var material = new THREE.MeshBasicMaterial( {color: 0x0000ff, wireframe: true} )
      //var material = new THREE.MeshStandardMaterial()

      const vertices = [];
      const sphere = new THREE.SphereGeometry(0.5, 16, 8);

      //const sprite = new THREE.TextureLoader().load( '/textures/circle.png' );

      for (let i = 0; i < 10000; i++) {
        const x = 2000 * Math.random() - 1000;
        const y = 2000 * Math.random() - 1000;
        const z = 2000 * Math.random() - 1000; //-i * 2;

        vertices.push(x, y, z);

        //ajout des lumieres
        /*if (z % 200 == 0) {
          console.log(z)
        const light = new THREE.PointLight( 0x6bff67, 30, 100 );
        light.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xff0040 } ) ) );
        light.position.set( x-10, y+10, z+2 );
        this.scene.add( light );
        }*/
      }
      /*
      for (let i = 0; i < 500; i++) {
        const x = 200 * Math.random() - 100;
        const y = 200 * Math.random() - 100;
        const z = -i * 40;

        vertices.push(x, y, z);

        const cube = new THREE.Mesh(geometry,material)
        cube.position.x = x
        cube.position.y = y
        cube.position.z = z

        this.scene.add( cube )

        //ajout des lumieres
        if (z % 50 == 0) {
          console.log(z)
        const light = new THREE.PointLight( 0x6bff67, 30, 400 );
        light.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0xff0040 } ) ) );
        light.position.set( x-10, y+10, z+2 );
        this.scene.add( light );
        }

        
      }
      */

      //console.log(sprite)
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );

      this.material = new THREE.PointsMaterial({
        size: 3,
        sizeAttenuation: true,
        alphaTest: 0.5,
        transparent: true,
        color: this.color,
      });
      // material.color.setHSL(0.01, 0.91, 0.60);
      //material.color.setHSL(1.0, 0.1, 1.0);

      this.particles = new THREE.Points(geometry, this.material);
      this.scene.add(this.particles);

      this.pointlight = new THREE.DirectionalLight(0xffffff, 2.5);
      //scene.add(light)
      //this.pointlight = new THREE.PointLight(0xffffff, 1.5);
      this.pointlight.position.x = 0;
      this.pointlight.position.y = 4;
      this.pointlight.position.z = 10;
      this.pointlight.lookAt(0, -4, 0);
      this.scene.add(this.pointlight);

      this.world = new CANNON.World();
      this.world.gravity.set(0.05, -5, 0);

      /*let solMat = new THREE.MeshPhongMaterial({
        color: 0x000000,
        side: THREE.DoubleSide,
        //transparent: true,
        reflectivity: 0.8,
      });
      let solGeo = new THREE.BoxGeometry(1500, 5000, 1500, 10);
      solGeo.rotateX(-Math.PI / 2)

      let sol = new THREE.Mesh(solGeo, solMat);
      sol.position.y = 6;
      sol.receiveShadow = true;
      this.scene.add(sol);*/

      //const planeShape = new CANNON.Plane()

      //definition du sol
      const planeShape = new CANNON.Box(new CANNON.Vec3(750, 1, 750));
      const planeBody = new CANNON.Body({ mass: 0 });

      planeBody.addShape(planeShape);
      planeBody.position.y = -4;
      this.world.addBody(planeBody);
      planeBody.position.y = -5;
      this.world.addBody(planeBody);

      //const loader = new THREE.TextureLoader();

      /* const SolGeom = new THREE.BoxGeometry(750, 1, 750, 2, 2, 2);
      const SolMat = new THREE.MeshPhongMaterial({
          color: 0xffffff,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.3,
          reflectivity: 0.8,
          roughness: 0.2,
          metalness: 0.9,
          roughnessMap: SolTexture,
          envMap: SolTexture,
        });
      const SolMesh = new THREE.Mesh(SolGeom, SolMat);
      SolMesh.position.y = -4
      this.scene.add(SolMesh) */

      //definition du mur gauche
      const mgShape = new CANNON.Box(new CANNON.Vec3(1, 750, 750));
      const mgBody = new CANNON.Body({ mass: 0 });

      mgBody.addShape(mgShape);
      if (window.innerWidth < 450) {
        mgBody.position.x = -4;
      } else {
        mgBody.position.x = -14;
      }

      this.world.addBody(mgBody);

      //definition du mur avant
      const maShape = new CANNON.Box(new CANNON.Vec3(750, 750, 1));
      const maBody = new CANNON.Body({ mass: 0 });

      maBody.addShape(maShape);
      maBody.position.z = 5;
      this.world.addBody(maBody);

      //definition du mur du fond
      const mbShape = new CANNON.Box(new CANNON.Vec3(750, 750, 1));
      const mbBody = new CANNON.Body({ mass: 0 });

      mbBody.addShape(mbShape);
      mbBody.position.z = -8;
      this.world.addBody(mbBody);

      //definition du mur droite
      const mdShape = new CANNON.Box(new CANNON.Vec3(1, 750, 750));
      const mdBody = new CANNON.Body({ mass: 0 });

      mdBody.addShape(mdShape);
      if (window.innerWidth < 450) {
        mdBody.position.x = 8;
      } else {
        mdBody.position.x = 16;
      }
      this.world.addBody(mdBody);

      this.tabBalle = [];
      this.tabPhysBalle = [];

      this.createBalle();

      this.createControls();

      this.clock = new THREE.Clock();

      this.renderer.render(this.scene, this.camera);
    },

    createControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.minDistance = 12;
      this.controls.maxDistance = 200;
      this.controls.dampingFactor = 0.05;
      this.controls.screenSpacePanning = false;
      this.controls.maxPolarAngle = Math.PI / 2;

      this.controls.update();
    },

    createBalle() {
      const size = 1.2;
      //const sphereShape = new CANNON.Sphere(diam);
      //const sphereGeometry = new THREE.SphereGeometry(diam, 20, 20);
      const geom = new THREE.BoxGeometry(size, size, size, 2, 2, 2);
      const shape = new CANNON.Box(
        new CANNON.Vec3(size / 2, size / 2, size / 2)
      );
      let Xstart = -2;
      const MetalTexture = new THREE.TextureLoader().load(
        "textures/metal1.jpg"
      );
      for (let i = 0; i < 31; i++) {
        let l = i + 1;
        if (i < 8) {
          var texture = new THREE.TextureLoader().load(
            "lettres/l" + l + ".jpg"
          );
        } else {
          var texture = new THREE.TextureLoader().load(
            "lettres/l" + l + ".png"
          );
        }

        //console.log(l,texture);
        let balleMat = new THREE.MeshPhysicalMaterial({
          color: 0xd0d0d0,
          side: THREE.DoubleSide,
          transparent: false,
          map: texture,
          reflectivity: 0.8,
          roughness: 0.7,
          metalness: 0.5,
          roughnessMap: MetalTexture,
          clearcoat: 0.7,
          clearcoatMap: MetalTexture,
          envMapIntensity: 5,
        });
        let sphereMesh = new THREE.Mesh(geom, balleMat);
        let sphereBody;
        if (i < 8) {
          sphereMesh.position.x = Xstart; //+ i * 0.05;
          sphereMesh.position.y = i * 5 + 20;
          sphereBody = new CANNON.Body({
            mass: 1,
            material: new CANNON.Material({ friction: 1, restitution: 0 }),
          });
        } else {
          sphereMesh.position.x = Xstart + (-8 + Math.random() * 16);
          sphereMesh.position.y = i * 3 + 85;
          sphereBody = new CANNON.Body({
            mass: 0.5,
            material: new CANNON.Material({ friction: 0.8, restitution: 0.2 }),
          });
        }

        sphereMesh.castShadow = true;
        this.tabBalle.push(sphereMesh);
        this.scene.add(this.tabBalle[i]);

        sphereBody.addShape(shape);
        sphereBody.position.x = sphereMesh.position.x;
        sphereBody.position.y = sphereMesh.position.y;
        sphereBody.position.z = sphereMesh.position.z;
        this.tabPhysBalle.push(sphereBody);
        this.world.addBody(this.tabPhysBalle[i]);
      }
    },
    modifGravity(e) {
      //console.log()
      if (this.vitesse != 2) return;
      if (
        e.clientX - this.windowHalfX < -this.windowHalfX / 2 ||
        e.clientX - this.windowHalfX > this.windowHalfX / 2
      ) {
        let div = 200;
        if (window.innerWidth < 400) div = 100;
        let gravity = (e.clientX - this.windowHalfX) / div;
        this.world.gravity.set(gravity, -0.3, 0);
      } else {
        this.world.gravity.set(0, -5, 0);
      }
    },
    animate() {
      this.idAnim = requestAnimationFrame(this.animate);

      this.controls.update();

      /*this.camera.position.z -= this.vitesse;
      this.pointlight.position.z -= this.vitesse-50;
      this.z_value = this.camera.position.z;
      if (this.camera.position.z < -19500) {
        this.camera.position.z = 10;
      }
      if (this.camera.position.z > 800) {
        this.camera.position.z = -10000;
      }*/

      this.particles.rotation.z += this.vitesse / 5000;
      this.particles.rotation.y += this.vitesse / 5000;
      /*console.log(this.particles.position.z)
      //this.pointlight.position.z -= this.vitesse-50;
      this.z_value = this.particles.position.z;
      if (this.particles.position.z > -19500) {
        this.particles.position.z = 10;
      }
      if (this.particles.position.z < 800) {
        this.particles.position.z = -10000;
      }*/

      let delta = Math.min(this.clock.getDelta(), 0.2);
      this.world.step(delta);

      for (let p = 0; p <= 30; p++) {
        //this.tabPhysBalle[p].quaternion.z=0
        this.tabBalle[p].position.copy(this.tabPhysBalle[p].position);
        this.tabBalle[p].quaternion.copy(this.tabPhysBalle[p].quaternion);
      }

      /*if (this.tabPhysBalle[19].position.y < 10) {
        setTimeout(() => {
          this.world.gravity.set(-0.1, 0.05, 0);
        }, 3000)
        
      }*/

      this.renderer.render(this.scene, this.camera);
    },
    accelereRalanti(mode) {
      //console.log(mode);

      switch (mode) {
        case "a":
          this.world.gravity.set(-0.05, 1.5, 0);
          this.vitesse = 20;
          break;
        case "r":
          this.world.gravity.set(0, -5, 0);
          this.vitesse = 2;
          break;
        case "m":
          this.world.gravity.set(-0.01, 0.8, 0);
          this.vitesse = 10;
          break;
        case "b":
          this.vitesse = -10;
          break;
        default:
          break;
      }
    },
    onWindowResize(e) {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    OuvreAcceuil() {
      this.world.gravity.set(-0.05, 10, 0);
      var tl = gsap.timeline();
      tl.to("#entete", { opacity: 0, duration: 0.5, ease: "power2.out" });
      tl.to(
        ".bouton",
        { opacity: 0, duration: 0.5, ease: "power2.out" },
        "-=0.5"
      );
      tl.to(
        ".techno-txt",
        { opacity: 0, duration: 0.2, ease: "power2.out", stagger: 0.1 },
        "-=1"
      );
      tl.to(
        ".universe",
        { opacity: 0, duration: 0.2, ease: "power2.out" },
        "-=1"
      );
      tl.to(this, {
        vitesse: 0,
        duration: 2,
        onComplete: () => {
          cancelAnimationFrame(this.idAnim);
          router.push("/Acceuil");
        },
      });

      /*
      const routeAcceuil = function() {
        router.push('/Acceuil')
      }*/
      //this.vitesse = 60

      //setTimeout(routeAcceuil, 1000)
    },
    changeFog(f) {
      if (f == "-") {
        this.fog = 0.01;
        this.scene.fog = new THREE.FogExp2(0x000000, this.fog);
      } else {
        this.fog = 0.002;
        this.scene.fog = new THREE.FogExp2(0x000000, this.fog);
      }
    },
    onresize() {},
    ouvreContact() {
      //this.stopAnimation()
      //router.push('/contact')
      this.world.gravity.set(-0.05, 10, 0);
      var tl = gsap.timeline();
      tl.to("#entete", { opacity: 0, duration: 0.5, ease: "power2.out" });
      tl.to(
        ".bouton",
        { opacity: 0, duration: 0.5, ease: "power2.out" },
        "-=0.5"
      );
      tl.to(
        ".techno-txt",
        { opacity: 0, duration: 0.2, ease: "power2.out", stagger: 0.1 },
        "-=1"
      );
      tl.to(
        ".universe",
        { opacity: 0, duration: 0.2, ease: "power2.out" },
        "-=1"
      );
      tl.to(this, {
        vitesse: 0,
        duration: 1,
        onComplete: () => {
          cancelAnimationFrame(this.idAnim);
          router.push("/contact");
        },
      });
    },
    stopAnimation() {
      console.log("cassé");
      cancelAnimationFrame(this.idAnim);
    },
    colorChange(code) {
      //console.log(this.particles.material.color.r);
      this.color = FCT.trouveColor(code);
      //console.log(this.color);
      // this.particles.material.color = FCT.trouveColor(code);
      VueCookies.set("atomColor", code, "1y");

      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  border: 1px black solid;
  text-decoration: underline;
  overflow: hidden;
}

.entete {
  width: 60%;
  position: absolute;
  top: 5vh;
  left: 5vw;
  font-size: 40px;
  font-weight: bold;
  color: white;
  opacity: 0;
  overflow: hidden;
  background-color: transparent;

  .titre-svg path {
    stroke-dasharray: 3000px;
    stroke-dashoffset: 3000px;
  }
}

.universe {
  position: absolute;
  bottom: 10vh;
  right: 10%;
  font-size: 20px;
  font-weight: bold;
  color: white;
  overflow: hidden;
  background-color: transparent;

  .universe-svg path {
    stroke-dasharray: 4000;
    stroke-dashoffset: 4000;
  }
}

.titre {
  position: relative;
  display: inline-block;
  margin: 0 15px 0 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  border: 2px solid white;
  padding: 10px;
  transition: 0.5s ease-in-out;
  font-weight: bold;
  font-size: 20px;
  overflow: hidden;
  cursor: pointer;

  z-index: 1;

  &:hover {
    color: black;
    background-color: white;
    box-shadow: 0px 0px 20px white, 0px 0px 60px white, 0px 0px 100px white;
    transition-delay: 0.5s;
  }

  &::before {
    position: absolute;
    /*top: 50%;
          left: -30%;
          transform: translate(-110%, -50%) rotateZ(20deg);
          content: "";
          width: 20%;
          height: 140%;
          background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(162,159,159,1) 21%, rgba(251,249,249,1) 100%);
*/
    content: "";
    background-color: white;
    top: 50%;
    left: -20%;
    height: 2px;
    width: 0%;
    transition: 1s all ease;
    z-index: -1;
    opacity: 1;
    transform: rotateZ(45deg);
    border-radius: 50%;
  }
  &:hover::before {
    /*left: 140%;*/
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

.bouton {
  position: absolute;
  top: 40vh;
  left: 40vw;
  opacity: 0;
  text-shadow: 1px 1px 2px #0f0f0f;
}

.techno {
  position: absolute;
  top: 20vh;
  left: 10px;
  font-size: 22px;
  font-weight: bold;
  color: white;

  .techno-txt {
    left: 100px;
    opacity: 0;
    cursor: pointer;
    line-height: 40px;
    transition: 0.5s ease-in;

    &:hover {
      opacity: 1;
      color: red;
    }
  }
}

.couleur {
  position: absolute;
  top: 20px;
  right: 20px;
  border: rgb(56, 56, 56) 1px solid;
  padding: 8px;

  .btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px 0;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.cannon {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 65%;
  height: 20%;
  z-index: 10;
  border: 1px dotted white;
}

@media screen and (max-width: 450px) {
  .universe {
    right: 0;
    left: 10vw;
  }
}
</style>
