import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/Acceuil",
    name: "Acceuil",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Acceuil.vue"),
  },
  {
    path: "/Projects",
    name: "Projects",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Projects.vue"),
  },
  {
    path: "/Competence",
    name: "Competence",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Competence.vue"),
  },
  {
    path: "/cv",
    name: "cv",
    component: () => import(/* webpackChunkName: "about" */ "../views/cv.vue"),
  },
  {
    path: "/futureCity",
    name: "futureCity",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/futureCity.vue"),
  },
  {
    path: "/diaporama",
    name: "diaporama",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/diaporama.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contact.vue"),
  },
  {
    path: "/game3d",
    name: "game3d",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/game3d.vue"),
  },
  {
    path: "/project3d",
    name: "project3d",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/project3d.vue"),
  },
  {
    path: "/threeExample",
    name: "threeExample",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/threeExample.vue"),
  },
  {
    path: "/vitrine",
    name: "vitrine",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vitrine.vue"),
  },
  {
    path: "/other",
    name: "other",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/other.vue"),
  },
];

const router = createRouter({
  //history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

export default router;
