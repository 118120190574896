<template>
  
  <router-view/>
</template>

<style lang="scss">
::before ::after{
  margin: 0;
  padding: 0;
}

*{
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
  background-color: black;
}

#app {
  font-family: calibri, Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

.entete{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        background-color: black;
        padding: 0 20px 0 0 ;
        width: 100%;
        
        

        .titre{
            font-size: 45px;
            padding: 10px 30px;
            color: white;
            transition: 0.3s;
            .titre-en{
                font-size: 14px;
                text-align: right;
            }
        }

        .retour{
            font-size: 25px;
            padding: 10px 30px;
            color: white;
            cursor: pointer;
            transition: 1s ease-out;

            &:hover{
                scale: 1.1;
            }
        }

    }

.erreur {
  border: 1px solid red;
  color: red;
  background-color: rgb(255, 164, 164);
  font-weight: 500;
  width: 90%;
  text-align: center;
  padding: 0.3em;
}

.btnRond {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: cyan;
    color: black;
    opacity: 0;
    font-size: 35px;
    width: 40px;
    height: 40px;
    padding: 0.3em;
    font-weight: 400;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    vertical-align: center;
  }

  .navigation {
    position: fixed;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;  
    padding: 8px 30px 0 0;
    color: #f0f0f0;
    background-color: rgba(0,0,0,0.4);
    top: 0;
    z-index: 10;

    .lien {
      cursor: pointer;
      border-bottom: 1px solid #f0f0f0;
      transition: 0.2s ease;

      &:hover {
        border-bottom: 4px solid #f0f0f0;
      }

      &:active {
        scale: 0.6;
      }
    }
  }
</style>
